// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\Jam\\workspace\\jczerwinski.xyz\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("C:\\Users\\Jam\\workspace\\jczerwinski.xyz\\src\\templates\\blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\Jam\\workspace\\jczerwinski.xyz\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cv-js": () => import("C:\\Users\\Jam\\workspace\\jczerwinski.xyz\\src\\pages\\cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-bak-js": () => import("C:\\Users\\Jam\\workspace\\jczerwinski.xyz\\src\\pages\\index.bak.js" /* webpackChunkName: "component---src-pages-index-bak-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\Jam\\workspace\\jczerwinski.xyz\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("C:\\Users\\Jam\\workspace\\jczerwinski.xyz\\.cache\\data.json")

